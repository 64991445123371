<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <div class="card card-custom card-stretch">
                    <div class="card-body pt-4">
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <div class="col-md-6">
                                <v-text-field
                                    v-model="name"
                                    :rules="name_rules"
                                    label="Tên"
                                    required
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    v-model="thumbnail"
                                    label="Hình ảnh (Sử dụng ảnh vuông)"
                                    required
                                    :append-icon="'mdi-paperclip'"
                                    @click:append="browse_file"
                                ></v-text-field>
                            </div>

                            <div class="col-md-6">
                                <v-text-field
                                    type="number"
                                    v-model="ordinal"
                                    label="Ordinal"
                                ></v-text-field>
                            </div>

                            <input type="file" ref="upload" @change="upload_server" style="display:none;">

                            <div class="col-md-12">
                                <h5>Nội dung</h5>
                                <vue-editor v-model="content"></vue-editor>
                            </div>

                            <input type="file" ref="upload" @change="upload_server" style="display:none;">
                            
                            <div class="col-md-12">
                                <v-btn class="ma-2 float-right" :loading="loading" color="info" @click="update_post">Sửa</v-btn>
                                <v-btn class="ma-2 float-right" :loading="loading" @click="cancel">Huỷ</v-btn>
                            </div>
                        </v-form>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>

<script>
// import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { VueEditor } from "vue2-editor";

export default {
    metaInfo: {
        title: 'Sửa tin tức'
    },
    data() {
        return {
            valid: true,
            id: null,
            name: "",
            content: "",
            ordinal: 0,
            thumbnail: "",
            create_time: null,
            loading: false,
            name_rules: [],
        };
    },
    watch: {
        post(val) {
            this.id = val.Id;
            this.name = val.Name;
            this.content = val.Content;
            this.ordinal = val.Ordinal;
            this.create_time = val.Create_time;
            this.thumbnail = val.Thumbnail;
        }
    },
    computed: {
        post() {
            return this.$store.getters['posts/get_post'];
        },
    },
    components: {
        VueEditor
    },
    methods: {
        update_post() {
            this.loading = true;
            var payload = {
                Id: this.id,
                Name: this.name,
                Content: this.content,
                Ordinal: parseInt(this.ordinal),
                Thumbnail: this.thumbnail,
                Create_time: this.create_time,
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('posts/update_post', payload).then(() => {
                this.loading = false;
                this.$router.push({ name: 'posts-index' });
            }).catch(() => {
                this.loading = false;
            });
        },
        cancel() {
            this.$router.push({ name: 'posts-index' });
        },
        browse_file() {
            this.$refs.upload.click();
        },
        upload_server() {
            var self = this;
            this.$store.dispatch('uploads/upload', {file: this.$refs.upload.files[0]}).then(res => {
                self.thumbnail = res.data.url;
            }).catch(e => {
                console.log(e);
            });
        }
    },
    mounted() {
        // this.$store.dispatch(SET_BREADCRUMB, [ { title: "Từ khoá", route: "index" }, { title: "Sửa từ khoá" } ]);
        this.$store.dispatch('posts/get_post_by_id', {id: this.$route.params.id});
    }
};
</script>



